// category
export const GET_CATEGORIES = "categories/getCategories";
export const GET_CATEGORY = "categories/getCategory";
export const EXPAND_CATEGORY = "categories/expandCategory";
export const COLLAPSE_CATEGORY = "categories/collapseCategory";
export const ADD_CATEGORY = "categories/addCategory";
export const RENAME_CATEGORY = "categories/renameCategory";
export const MOVE_CATEGORY = "categories/moveCategory";
export const DELETE_CATEGORY = "categories/deleteCategory";
export const RESIZE_SIDEBAR = "categories/resizeSidebar";
export const TOGGLE_MOVE_TO_CATEGORY_MODAL =
  "categories/toggleMoveToCategoryModal";
export const TOGGLE_CATEGORY_ACTIONS = "categories/toggleCategoryActions";
export const GO_BACK = "categories/goBack";
export const MAKE_PATH = "categories/makePath";
export const DISABLE_MOVE_TO_BUTTON = "categories/disableMoveToButton";
export const SET_VISIBILITY_MOVE_TO_CATEGORY_MODAL =
  "categories/setVisibilityMoveToCategoryModal";

// events
export const GET_EVENTS_PER_PAGE = "events/getEventsPerPage";
export const ADD_EVENT_TO_CATEGORY = "events/addEventToCategory";
export const SEARCHED_EVENTS_PER_PAGE = "events/getSearchedEventsPerPage";
export const RESET_SEARCH_VALUE = "events/resetSearchValue";
export const LOAD_EVENTS = "events/loadEvents";
export const LOAD_EVENT = "events/loadEvent";
export const GET_EVENT_FILES_FOR_DOWNLOAD = "events/getEventFilesForDownload";
export const TOGGLE_EVENT_FILES_LIST = "events/toggleEventFilesList";
export const DOWNLOADING_EVENT_FILE = "events/downloadingEventFile";
export const FETCH_RECORDING_INSTANCES = "events/fetchRecoringInstances"
export const GET_RECORDING_INSTANCES = "events/getRecordingInstances";
export const GET_EVENT = "events/getEvent";
export const GET_RECORDING_INSTANCE = "events/getRecoringInstance";
export const GET_RECORDING_INSTANCE_TRANSCRIPTION_STATUS = "events/getRecordingInstanceTranscriptionStatus"
export const CHANGE_RECORDING_INSTANCE = "events/changeRecordingInstance";
export const DELETE_RECORDING_INSTANCE = "events/deleteRecordingInstance";
export const CHANGE_NO_DATA_REDIRECT = "events/changeNoDataRedirect";
export const CHANGE_EVENT_LIST = "events/changeShowEventList"
export const CHANGE_CLEAR_DATE = "events/changeClearDate"
export const GET_SELECTED_RECORDING_INSTANCE = "events/getSelectedRecordingInstance";
export const SET_TRANSCRIPTION_STATUS = "events/setTranscriptionStatus";
export const SET_FLOOR_FILLED_VIDEO_STATUS = "events/setFloorFilledVideoStatus";
export const SET_MEDIA_FILE_OFFSET = "events/setMediaFileOffset";
export const TOGGLE_SHOW_SEGMENTED_FILES = "events/toggleShowSegmentedFiles";
export const TOGGLE_SHOW_INSTANCES_WITH_TEXT_FILES_ONLY = "events/toggleShowInstancesWithTextFilesOnly";


//user
export const SIGN_IN_USER = "users/signIn";
export const LOG_OUT = "users/logout";
export const MOBILE_MENU_TOGGLE = "users/mobileMenuToggle";
export const CHANGE_ORGANIZATION = "preferences/changeOrganization";
export const SET_DEFAULT_ORGANIZATION = "preferences/setDefaultOrganization";

//preferences
export const CHANGE_LANGUAGE = "preferences/changeLanguage";

// error
export const SET_ERROR = "errors/setError";
export const RESET_ERROR = "errors/resetError";
export const SET_GLOBAL_ERROR = "errors/setGlobalError";
export const RESET_GLOBAL_ERROR = "errors/resetGlobalError";

//naviagtion
export const GET_SELECTED_CATEGORY_AND_SEARCH_VALUE =
  "categories/getSelectedCategoryAndSearchValue";
export const GET_SELECTED_CATEGORY_AND_SELECTED_EVENT =
  "categories/getSelectedCategoryAndSelectedEvent";
export const GET_SELECTED_EVENT_AND_SELECTED_RECORDING_INSTANCE =
  "categories/getSelectedEventAndSelectedRecordingInstance";
export const ON_ERROR_REDIRECT = "categories/errorRedirect";
export const GO_BACK_FROM_RECORDING_INSTANCES =
  "events/goBackFromRecordingInsatnces";
export const GO_BACK_FROM_DOWNLOAD_FILES = "events/goBackFromDownloadFiles";

