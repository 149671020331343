import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    MenuItem,
    Typography,
    Checkbox,
    Select,
    InputLabel,
    FormControlLabel
  } from "@mui/material";
  import { useTranslation } from "react-i18next";

import LoaderBar from "../LoaderBar/LoaderBar";
import * as S from "../../styled";
import palette from "../../material-ui/theme/colors";
import { MOCK_LANGUAGES } from '../../shared/Language'

const TRANSCRIPTIONS_GENERATE_API_KEY = 'a67c2038610d412caa07ccb3e9c2d0da';
const TRANSCRIPTIONS_GENERATE_AZURE_REGION = 'germanywestcentral';
const SAS_URL = 'https://interprefyaistorage.blob.core.windows.net/interprefyblob?sp=racwdli&st=2024-10-28T16:05:56Z&se=2025-10-28T00:05:56Z&spr=https&sv=2022-11-02&sr=c&sig=q5qQeApceZp4erjCSBeDgRcEOxOsxe91QfH13Jurx%2B0%3D';
const CREATE_TRANSCRIPTIONS_URL = `https://${TRANSCRIPTIONS_GENERATE_AZURE_REGION}.api.cognitive.microsoft.com/speechtotext/v3.2-preview.2/transcriptions`;

const AudioVideoTranscription = () => {
    const { t } = useTranslation();
    const fileInputRef = useRef(null); 

    const [file, setFile] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [diarizationEnabled, setDiarizationEnabled] = useState(false);
    const [timestampsEnabled, setTimestampsEnabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [transcriptionCompleted, setTranscriptionCompleted] = useState(false);
    const [transcriptContent, setTranscriptContent] = useState('');
    const [progress, setProgress] = useState(0);
    const [languages, setLanguages] = useState([]);


    useEffect(() => {
        setLanguages(MOCK_LANGUAGES);
        setSelectedLanguage(MOCK_LANGUAGES[0].code);
    }, []);

    useEffect(() => {
        if (isLoading) {
            setProgress(0);
        }
    }, [isLoading]);

    const handleFileUpload = event => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile && (uploadedFile.type.startsWith('audio/') || uploadedFile.type.startsWith('video/'))) {
            setFile(uploadedFile);
            setError('');
            setTranscriptionCompleted(false);
            setTranscriptContent('');
            setProgress(0);
        } else {
            setError('Please upload a valid audio or video file.');
            setFile(null);
        }
    };

    const generateTranscript = async () => {
        if (!file) {
            setError('Please upload an audio or video file first.');
            return;
        }

        setIsLoading(true);
        setError('');
        setTranscriptionCompleted(false);
        setTranscriptContent('');
        setProgress(10);

        try {
            const fileUrl = await uploadFileToBlob();
            await createTranscriptionJob(fileUrl);
        } catch (err) {
            setError('An error occurred while generating the transcript. Please check the console for more details.');
            setProgress(0);
            setIsLoading(false);
        }
    };

    const uploadFileToBlob = async () => {
        try {
            const fileName = file.name;
            const urlWithoutToken = SAS_URL.split('?')[0];
            const fullUrl = `${urlWithoutToken}/${fileName}${SAS_URL.substring(SAS_URL.indexOf('?'))}`;
            const response = await fetch(fullUrl, {
                method: 'PUT',
                headers: { 'x-ms-blob-type': 'BlockBlob' },
                body: file,
            });

            if (!response.ok) {
                const errorDetails = await response.text();
                throw new Error(`Upload failed: ${response.status} ${response.statusText}, ${errorDetails}`);
            }

            const fileUrl = urlWithoutToken + '/' + fileName;

            const availabilityCheck = await fetch(fileUrl);
            if (!availabilityCheck.ok) {
                throw new Error('File access check failed.');
            }
            return fileUrl;
        } catch (err) {
            setError(`Failed to upload the file: ${err.message}`);
            throw err;
        }
    };

    const createTranscriptionJob = async (fileUrl) => {
        try {
            const body = JSON.stringify({
                contentUrls: [fileUrl],
                locale: selectedLanguage,
                displayName: 'My Transcription',
                properties: {
                    diarizationEnabled: diarizationEnabled,
                    wordLevelTimestampsEnabled: true,
                    punctuationMode: "DictatedAndAutomatic",
                    profanityFilterMode: "Masked"
                }
            });

            const response = await fetch(
                CREATE_TRANSCRIPTIONS_URL,
                {
                    method: 'POST',
                    headers: {
                        'Ocp-Apim-Subscription-Key': TRANSCRIPTIONS_GENERATE_API_KEY,
                        'Content-Type': 'application/json',
                    },
                    body: body,
                }
            );

            const responseText = await response.text();
            if (!response.ok) {
                throw new Error(`Transcription job failed: ${response.status} ${response.statusText}, ${responseText}`);
            }

            const data = JSON.parse(responseText);
            pollTranscriptionStatus(data.self);
            return data;
        } catch (err) {
            setError(`Failed to create transcription job: ${err.message}`);
            throw err;
        }
    };

    const pollTranscriptionStatus = async (transcriptionUrl) => {
        try {
            const response = await fetch(transcriptionUrl, {
                method: 'GET',
                headers: {
                    'Ocp-Apim-Subscription-Key': TRANSCRIPTIONS_GENERATE_API_KEY
                }
            });

            const data = await response.json();

            if (data.status === 'Succeeded') {
                setProgress(100);
                const transcriptText = await getTranscriptionResult(data.links.files);
                setTranscriptContent(transcriptText);
                setTranscriptionCompleted(true);
                setIsLoading(false);
            } else if (data.status === 'Failed') {
                setProgress(0);
                setError(`Transcription failed. Details: ${JSON.stringify(data.properties)}`);
                setIsLoading(false);
            } else {
                setProgress(prev => (prev < 90 ? prev + 1 : 90));
                setTimeout(() => pollTranscriptionStatus(transcriptionUrl), 1000);
            }
        } catch (err) {
            setProgress(0);
            setError('Error while checking transcription status.');
            setIsLoading(false);
        }
    };

    const formatTimestamp = (offset) => {
        const totalSeconds = Math.floor(offset / 10000000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const getTranscriptionResult = async (resultUrl) => {
        try {
            const response = await fetch(resultUrl, {
                headers: { 'Ocp-Apim-Subscription-Key': TRANSCRIPTIONS_GENERATE_API_KEY }
            });
            const data = await response.json();
            if (data.error) {
                throw new Error(`Transcription result error: ${data.error.message}`);
            }

            if (data.values && data.values.length > 0) {
                const transcriptFileUrl = data.values.find(v => v.kind === 'Transcription')?.links?.contentUrl;
                if (transcriptFileUrl) {
                    const transcriptResponse = await fetch(transcriptFileUrl, {
                        headers: { 'Ocp-Apim-Subscription-Key': TRANSCRIPTIONS_GENERATE_API_KEY }
                    });
                    const transcriptData = await transcriptResponse.json();
                    const transcriptSet = new Set();

                if (transcriptData.recognizedPhrases && transcriptData.recognizedPhrases.length > 0) {
                    let maxOffsetInTicks = 0;
                    transcriptData.recognizedPhrases.forEach(phrase => {
                        if (phrase.offsetInTicks < maxOffsetInTicks) {
                            return;
                        }
                        maxOffsetInTicks = phrase.offsetInTicks;
                        const startTime = formatTimestamp(phrase.offsetInTicks);
                        const endTime = formatTimestamp(phrase.offsetInTicks + phrase.durationInTicks);
                        const text = phrase.nBest && phrase.nBest.length > 0 ? phrase.nBest[0].display : 'No text available';
                    
                        const transcriptEntry = timestampsEnabled 
                            ? `[${startTime} - ${endTime}] ${text}`
                            : `${text}`;
                        
                        transcriptSet.add(transcriptEntry);
                    });
                }

                return Array.from(transcriptSet).join('\n');
                }
            }
            throw new Error('No recognized phrases found in the transcription result.');
        } catch (err) {
            throw new Error('Failed to retrieve transcription result');
        }
    };

    const downloadTranscript = () => {
        if (!transcriptContent) {
            setError('No transcript available to download.');
            return;
        }

        const element = document.createElement("a");
        const file = new Blob([transcriptContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "transcript.txt";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    return (
        <>
            <S.FlexDiv
                className='AudioVideoTranscription'
                width="100%"
                height="100%"
                padding=" 48px 0 0"
                justifyContent="start"
                alignItems="center"
                direction="column"
            >
                <Typography variant="h3" align="center" color="primary">
                    {t("transcription.pageTitle")}
                </Typography>
                <S.FlexDiv
                    width="450px"
                    height="auto"
                    direction="column"
                    justifyContent="start"
                >
                    <input
                        ref={fileInputRef}
                        type='file'
                        accept='audio/*,video/*'
                        onChange={handleFileUpload}
                        id='fileInput'
                    />
                    {!file &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => fileInputRef.current.click()}
                            sx={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "20px",
                                marginBottom: "24px",
                            }}
                            disabled={file}
                        >
                            <Typography variant="p1_bold" align="center">
                                {t("transcription.uploadFile")}
                            </Typography>
                        </Button>
                    }
                    {file && (
                        <Typography
                            variant="p1_bold"
                            align="center"
                            sx={{
                                marginBottom: "16px",
                            }}
                        >
                            {t("transcription.fileSelected")} {file.name}
                        </Typography>
                    )}
                    <InputLabel
                        id="languageSelect"
                        sx={{
                            marginRight: "auto",
                            marginBottom: "8px"
                        }}
                    >
                        <Typography variant="p1_bold" align="left">
                            {t("transcription.selectLanguage")}
                        </Typography>
                    </InputLabel>
                    <Select
                        labelId="languageSelect"
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        sx={{ width: "100%" }}
                    >
                        {languages.map((lang) => (
                            <MenuItem
                                key={lang.code}
                                value={lang.code}
                                sx={{ padding: "1.2rem 1.6rem" }}
                            >
                                <Typography variant="p1" align="left">
                                    {lang.name}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                    <FormControlLabel
                        sx={{
                            paddingTop: "16px",
                            alignSelf: "flex-start",
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            "& .MuiTypography-root": { fontSize: "1.6rem" },
                            paddingLeft: "0px",
                        }}
                        control={
                            <Checkbox
                                checked={diarizationEnabled}
                                onChange={(e) => setDiarizationEnabled(e.target.checked)}
                                color="primary"
                                
                            />
                        }
                        label={t("transcription.enableDiarization")}
                    />
                    <FormControlLabel
                        sx={{
                            paddingBottom: "16px",
                            alignSelf: "flex-start",
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            "& .MuiTypography-root": { fontSize: "1.6rem" },
                            paddingLeft: "0px",
                        }}
                        control={
                            <Checkbox
                                checked={timestampsEnabled}
                                onChange={(e) => setTimestampsEnabled(e.target.checked)}
                                color="primary"
                                
                            />
                        }
                        label={t("transcription.enableTimestamps")}
                    />
                    {progress === 0 &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={generateTranscript}
                            disabled={!file || isLoading}
                            sx={{
                                width: "100%",
                                marginBottom: "16px",
                                padding: "8px",
                                borderRadius: "20px",
                            }}
                        >
                            <Typography variant="p1_bold" align="center">
                                {t(isLoading ? "transcription.generating": "transcription.generateTranscript")}
                            </Typography>
                        </Button>
                    }
                    {error && (
                        <Typography variant="p1_bold" align="center"
                            sx={{
                                marginBottom: "16px",
                                color: palette.red[100],
                            }}>
                            {error}
                        </Typography>
                    )}
                    {progress > 0 && !transcriptionCompleted && (
                        <LoaderBar progress={progress}/>
                    )}
                    {progress > 0 &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={downloadTranscript}
                            disabled={!transcriptionCompleted}
                            sx={{
                                width: "100%",
                                marginBottom: "16px",
                                padding: "8px",
                                borderRadius: "20px",
                            }}
                        >
                            <Typography variant="p1_bold" align="center">
                                {t("transcription.downloadTranscript")}
                            </Typography>
                        </Button>
                    }
                </S.FlexDiv>
            </S.FlexDiv>
        </>
    );
};

export default AudioVideoTranscription;
