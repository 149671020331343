import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import MobileNav from "../../components/MobileNav/MobileNav";

import interprefy from "../../assets/img/interprefy.svg";
import burger from "../../assets/img/burger.svg";
import personLight from "../../assets/img/person_light.svg";
import personDark from "../../assets/img/person_dark.svg";
import logOut from "../../assets/img/log_out.svg";

import * as S from "../../styled";
import {
  mobileMenuToggle,
} from "../../store/actions/userAction";
import { RootState } from "../../store/store";
import {
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import UserOrganizations from "../../components/UserOrganizations/UserOrganizations";
import {
  changeShowEventList,
  searchEventsPerPage,
} from "../../store/actions/eventAction";

const Header = ({ onLogout }: HeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.data);
  const showEventList = useSelector((state: RootState) => state.event.showEventList);
  const theme = useTheme();
  const isFHD = useMediaQuery(theme.breakpoints.up("xl"));
  const btnRef = useRef<any | null>();
  const [toggleBtn, setToggleBtn] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const selectedCategory = useSelector(
    (state: RootState) => state?.category?.selectedCategory
  );
  const sortedBy = useSelector((state: RootState) => state.event?.sortedBy);
  const timezone = useSelector((state: RootState) => state.event?.timezone);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToggleBtn(!toggleBtn);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setToggleBtn(false);
    setAnchorEl(null);
  };

  const logout = async () => {
    history.push("/media-library");
    onLogout();
  };

  const openMobileMenu = () => {
    dispatch(mobileMenuToggle());
  };

  const onLogoClick = () => {
    dispatch(
      searchEventsPerPage(
        undefined,
        selectedCategory.id,
        undefined,
        undefined,
        sortedBy,
        timezone,
        undefined
      )
    );
    history.push("/media-library");
    setTimeout(() => {
      dispatch(changeShowEventList(true));
    }, 500);
  };

  return (
    <>
      <MobileNav />
      <S.Navigation>
        <Container maxWidth={isFHD ? "xl" : "lg"} sx={{ height: "100%" }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={"100%"}
          >
            <S.HamburgerIcon
              src={burger}
              alt="hamburger mobile icon"
              onClick={() => openMobileMenu()}
            />
            <S.InterprefyLogo
              src={interprefy}
              alt="logo interprefy"
              data-test="logo"
              width={135}
              height={24}
              onClick={onLogoClick}
            />
            <Divider orientation="vertical" flexItem sx={{ my: 2 }} />
            <Typography variant="p1" sx={{ ml: 2, whiteSpace: "nowrap" }}>
              {t("header.mediaLibrary")}
            </Typography>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              width={"100%"}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => history.push("/transcript-generator")}
              >
                <Typography variant="p1_bold" align="center">
                  {t(showEventList ? "transcription.uploadFromExternal" : "transcription.upload")}
                </Typography>
              </Button>
              <UserOrganizations data-test="organizations-dropdown" />
            </Stack>
            {user.email && (
              <>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant={toggleBtn ? "alternateFilled" : "alternate"}
                  disableRipple
                  onClick={handleClick}
                  ref={btnRef}
                  sx={{ px: 3, ml: 2 }}
                  startIcon={
                    <img
                      src={!toggleBtn ? personLight : personDark}
                      alt="profile"
                    />
                  }
                >
                  <Typography variant="p1_bold" align="center">
                    {t("header.profile")}
                  </Typography>
                </Button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Typography
                    variant="p1"
                    align="center"
                    sx={{ px: 2, pt: 0.5 }}
                  >
                    {user.email}
                  </Typography>
                  <MenuItem onClick={() => logout()}>
                    <img src={logOut} alt="logout" />
                    <Typography variant="p1_bold" align="center">
                      {t("navigation.logout")}
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Stack>
        </Container>
        <Divider />
      </S.Navigation>
    </>
  );
};
interface HeaderProps {
  onLogout: Function;
}
export default Header;
