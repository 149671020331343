import { PURGE } from "redux-persist";
import {
  GET_EVENTS_PER_PAGE,
  ADD_EVENT_TO_CATEGORY,
  SEARCHED_EVENTS_PER_PAGE,
  RESET_SEARCH_VALUE,
  CHANGE_ORGANIZATION,
  LOAD_EVENTS,
  GO_BACK,
  GET_EVENT_FILES_FOR_DOWNLOAD,
  DOWNLOADING_EVENT_FILE,
  GET_CATEGORY,
  GO_BACK_FROM_DOWNLOAD_FILES,
  FETCH_RECORDING_INSTANCES,
  GET_RECORDING_INSTANCES,
  GO_BACK_FROM_RECORDING_INSTANCES,
  GET_EVENT,
  GET_RECORDING_INSTANCE,
  LOAD_EVENT,
  CHANGE_NO_DATA_REDIRECT,
  CHANGE_EVENT_LIST,
  GET_SELECTED_RECORDING_INSTANCE,
  SET_TRANSCRIPTION_STATUS,
  ON_ERROR_REDIRECT,
  SET_FLOOR_FILLED_VIDEO_STATUS,
  CHANGE_CLEAR_DATE,
  GET_RECORDING_INSTANCE_TRANSCRIPTION_STATUS,
  TOGGLE_EVENT_FILES_LIST,
  SET_MEDIA_FILE_OFFSET,
  TOGGLE_SHOW_SEGMENTED_FILES,
  CHANGE_RECORDING_INSTANCE,
  DELETE_RECORDING_INSTANCE,
  TOGGLE_SHOW_INSTANCES_WITH_TEXT_FILES_ONLY,
} from "../actions/types";
import { ALL_EVENTS_ID, CREATE_FLOOR_FILLED_VIDEO, TRANSCRIPTION } from "../../shared/constants";
import { Action } from "@reduxjs/toolkit";
import { ICategory } from "../../models/categories";
import { IEventItem, IRecordingInstance, IRecordingInstanceItem } from "../../models/events";
import { addPropertyIsDownloadingAndFormatDateAndAddOffset, addToEventsIsExtendedAndFormatDate, countMediaFloorFiles, setDownloadEventFiles, setPageNumber, updateExpandedRecordingInstanceFlag, updateItemsOnExpand, updateStatus } from "../../shared/utils-events";


export interface IEventState {
  events: IEventItem[],
  getEventsById: boolean,
  totalEvents: number,
  totalPages: number,
  pageNumber: number,
  sortedBy: { id: number, value: string, direction: string },
  timezone: { id: number, value: string },
  dateRange: { fromDate: string, toDate: string },
  searchValue: string,
  isLoadingEvents: boolean,
  isLoadingRecordingInstances: boolean,
  isLoadingEventFilesForDownload: boolean,
  eventFilesForDownload: any[],
  selectedEvent: IEventItem | {},
  showSearchBar: boolean,
  recordingInstances: IRecordingInstance[],
  selectedRecordingInstance: IRecordingInstance,
  redirectIfNoData: boolean,
  showEventList: boolean,
  clearDateValue: boolean,
  showSegmentedFiles: boolean,
  showInstancesWithTextFilesOnly: boolean
}
const initialState: IEventState = {
  events: [],
  getEventsById: false,
  totalEvents: 0,
  totalPages: 1,
  pageNumber: 0,
  sortedBy: { id: 4, value: "createdAt", direction: "desc" },
  timezone: { id: 1, value: "UTC" },
  dateRange: { fromDate: "", toDate: "" },
  searchValue: "",
  isLoadingEvents: false,
  isLoadingRecordingInstances: false,
  isLoadingEventFilesForDownload: false,
  eventFilesForDownload: [],
  selectedEvent: {},
  showSearchBar: true,
  recordingInstances: [],
  selectedRecordingInstance: {} as IRecordingInstance,
  redirectIfNoData: false,
  showEventList: true,
  clearDateValue: false,
  showSegmentedFiles: false,
  showInstancesWithTextFilesOnly: false
};

export interface IEventPayload extends IEventState {
  searchInput: string;
  eventId: string;
  recordingInstanceId: string;
  eventFileId: string;
  isDownloadingEventFile: boolean;
  isMobileWindowSize: boolean;
  hasParams: boolean;
  recordingInstance: IRecordingInstanceItem;
  selectedCategory: ICategory;
  event: IEventItem;
  transcriptions: any[];
  offsetInSeconds: number;
}
export interface ActionWithPayload<T> extends Action {
  payload: T;
  type: string;
}
// eslint-disable-next-line
export default (state: IEventState = initialState, action: ActionWithPayload<Partial<IEventPayload>>): IEventState => {
  switch (action.type) {
    case GET_EVENTS_PER_PAGE:
      const { totalEvents, totalPages, pageNumber, events } = action.payload;

      return {
        ...state,
        events:
          pageNumber === 1
            ? events!!
            : [...state.events, ...events!!],
        totalEvents: totalEvents!!,
        totalPages: totalPages!!,
        pageNumber: setPageNumber(totalPages, pageNumber),
        sortedBy: {
          id: action.payload.sortedBy?.id!!,
          value: action.payload.sortedBy?.value!!,
          direction: action.payload.sortedBy?.direction!!
        },
        timezone: {
          id: action.payload.timezone?.id!!,
          value: action.payload.timezone?.value!!,
        },
        getEventsById: false,
        searchValue: action.payload?.searchValue!!,
        dateRange: { fromDate: action.payload.dateRange?.fromDate!!, toDate: action.payload.dateRange?.toDate!! },
        isLoadingEvents: false,
        selectedEvent: {},
        showSearchBar: false,
      };
    case SEARCHED_EVENTS_PER_PAGE:
      return {
        ...state,
        events:
          action.payload.pageNumber === 1
            ? [...action.payload.events!]
            :
            [
              ...state.events,
              ...action.payload.events!!
            ],
        totalEvents: action.payload.totalEvents!!,
        totalPages: action.payload.totalPages!!,
        //@ts-ignore
        pageNumber:
          state.totalPages >= action.payload.pageNumber!!
            ? action.payload.pageNumber
            : state.totalPages,
        searchValue: action.payload.searchInput!!,
        dateRange: { fromDate: action.payload.dateRange?.fromDate!!, toDate: action.payload.dateRange?.toDate!! },
        sortedBy: {
          id: action.payload.sortedBy?.id!!,
          value: action.payload.sortedBy?.value!!,
          direction: action.payload.sortedBy?.direction!!
        },
        timezone: {
          id: action.payload.timezone?.id!!,
          value: action.payload.timezone?.value!!,
        },
        isLoadingEvents: false,
        showSearchBar: false,
      };
    case ADD_EVENT_TO_CATEGORY:
      return {
        ...state,
        events:
          action.payload.selectedCategory?.id !== ALL_EVENTS_ID
            ? state.events.filter(
              (event) => event.id !== action.payload.eventId
            )
            : state.events,
      };
    case RESET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: "",
        dateRange: { fromDate: "", toDate: "" },
      };
    case CHANGE_ORGANIZATION:
      return {
        ...state,
        totalPages: 1,
      };
    case LOAD_EVENTS:
      return {
        ...state,
        isLoadingEvents: true,
      };
    case LOAD_EVENT:
      return {
        ...state,
        isLoadingEvents: true,
      };
    case GO_BACK:
      return {
        ...state,
        searchValue: "",
        dateRange: { fromDate: "", toDate: "" },
      };
    case GET_SELECTED_RECORDING_INSTANCE:
      return {
        ...state,
        selectedRecordingInstance: action.payload.recordingInstance!!,
      };
    case SET_TRANSCRIPTION_STATUS:
      return {
        ...state,
        //@ts-ignore
        recordingInstances: updateStatus(state.recordingInstances, action.payload.selectedRecordingInstance?.id, TRANSCRIPTION),
      };
    case SET_FLOOR_FILLED_VIDEO_STATUS:
      return {
        ...state,
        //@ts-ignore
        recordingInstances: updateStatus(state.recordingInstances, action.payload.selectedRecordingInstance?.id, CREATE_FLOOR_FILLED_VIDEO),
      };
    case GET_CATEGORY:
      return {
        ...state,
        selectedEvent: {},
      };
    case CHANGE_NO_DATA_REDIRECT:
      return {
        ...state,
        redirectIfNoData: false,
      };
    case CHANGE_EVENT_LIST:
      return {
        ...state,
        //@ts-ignore
        showEventList: action.payload.showEventList,
        recordingInstances: action.payload.showEventList ? [] : state.recordingInstances,
        selectedEvent: action.payload.showEventList ? {} : state.selectedEvent,
        eventFilesForDownload: action.payload.showEventList ? [] : state.eventFilesForDownload,
        selectedRecordingInstance: action.payload.showEventList ? {} as IRecordingInstance : state.selectedRecordingInstance
      }
    case FETCH_RECORDING_INSTANCES:
      return {
        ...state,
        isLoadingRecordingInstances: true,
        //@ts-ignore
        selectedEvent: action.payload.event,
      }
    case CHANGE_CLEAR_DATE:
      return {
        ...state,
        //@ts-ignore
        clearDateValue: action.payload.clearDate
      }
    case GET_EVENT_FILES_FOR_DOWNLOAD:
      return {
        ...state,
        selectedEvent: action.payload.event!!,

        eventFilesForDownload: addPropertyIsDownloadingAndFormatDateAndAddOffset(
          // @ts-ignore
          action.payload.eventFilesForDownload
        ),
        recordingInstances: updateItemsOnExpand(
          state.recordingInstances,
          action.payload.recordingInstanceId,
          action.payload.hasParams
        ),
        redirectIfNoData: (action.payload.hasParams && action.payload.eventFilesForDownload?.length === 0) || false,
        //@ts-ignore
        selectedRecordingInstance: action.payload.isInterval ? state.selectedRecordingInstance : {
          ...state.recordingInstances.find(
            (recordingInstance) =>
              recordingInstance.id === action.payload.recordingInstanceId
          ), ...{ mediaFilesCount: countMediaFloorFiles(action.payload.eventFilesForDownload) }
        },
        isLoadingEventFilesForDownload: false,
      }
    case TOGGLE_EVENT_FILES_LIST:
      return {
        ...state,
        //@ts-ignore
        recordingInstances: updateExpandedRecordingInstanceFlag(
          state.recordingInstances,
          action.payload.recordingInstance?.id,
        ),
        eventFilesForDownload: [],
        selectedRecordingInstance: {} as IRecordingInstance,
        isLoadingEventFilesForDownload: !action.payload.recordingInstance?.isExpanded || state.isLoadingEventFilesForDownload,
      }
    case DOWNLOADING_EVENT_FILE:
      return {
        ...state, 
        eventFilesForDownload: setDownloadEventFiles(
          state.eventFilesForDownload,
          action.payload.eventFileId,
          action.payload.isDownloadingEventFile
        ),
      };
    case GO_BACK_FROM_DOWNLOAD_FILES:
      return {
        ...state,
        selectedRecordingInstance: action.payload.selectedRecordingInstance!!,
        eventFilesForDownload: [],
      };
    case GO_BACK_FROM_RECORDING_INSTANCES:
      return {
        ...state,
        selectedEvent: {},
        selectedRecordingInstance: {} as IRecordingInstance,
        showEventList: true
      };
    case GET_RECORDING_INSTANCES:
      return {
        ...state,
        //@ts-ignore
        recordingInstances: action.payload.recordingInstances,
        //@ts-ignore
        events: updateItemsOnExpand(state.events, action.payload.event?.id, action.payload.hasParams, action.payload.recordingInstances![0]),
        selectedEvent: { ...action.payload.event, isExpanded: true },
        showSearchBar: action.payload.isMobileWindowSize ? true : false,
        eventFilesForDownload: [],
        isLoadingRecordingInstances: false,
      };
    case GET_EVENT:
      return {
        ...state,
        //@ts-ignore
        events: addToEventsIsExtendedAndFormatDate([{ ...action.payload.event, isExpanded: true }]),
        selectedEvent: { ...action.payload.event, isExpanded: true },
        isLoadingEvents: false,
        totalEvents: 1,
      };
    case GET_RECORDING_INSTANCE:
      return {
        ...state,
        //@ts-ignore
        recordingInstances: [{ ...action.payload.recordingInstance, isExpanded: true }],
        selectedRecordingInstance: {
          ...action.payload.recordingInstance!!,
          isExpanded: true,
        },
        isLoadingEvents: false,
      };
    case GET_RECORDING_INSTANCE_TRANSCRIPTION_STATUS:
      const recordingInstance = action.payload.recordingInstance as IRecordingInstanceItem;
      return {
        ...state,
        //@ts-ignore
        selectedRecordingInstance: {
          ...state.selectedRecordingInstance,
          singleSegment: recordingInstance.singleSegment,
          transcriptionStatus: recordingInstance.transcriptionStatus,
          languageFloorFilledVideoStatus: recordingInstance.languageFloorFilledVideoStatus,
          transcriptions: recordingInstance.transcriptions,
          floorTranscriptLanguage: recordingInstance.transcriptions?.find(transcript => transcript.floor === true)?.languageCode,
        },
        isLoadingEvents: false,
      }
    case CHANGE_RECORDING_INSTANCE:
      const updatedRecordingInstances = [...state.recordingInstances];
      const index = updatedRecordingInstances.findIndex(instance => instance.id === action.payload.recordingInstance!!.id);
      updatedRecordingInstances[index] = {
        ...action.payload.recordingInstance!!,
        isExpanded: updatedRecordingInstances[index].isExpanded
      };

      return {
        ...state,
        recordingInstances: updatedRecordingInstances,
      };
    case DELETE_RECORDING_INSTANCE:
      const id = action.payload.recordingInstanceId;
      const updatedInstances = [...state.recordingInstances].filter(instance => instance.id !== id);
      return {
        ...state,
        recordingInstances: updatedInstances,
      };
    case PURGE:
      return initialState;
    case ON_ERROR_REDIRECT:
      return {
        ...state,
        selectedEvent: {},
        selectedRecordingInstance: {} as IRecordingInstance,
        isLoadingEvents: false
      }
    case SET_MEDIA_FILE_OFFSET:
      return {
        ...state,
        eventFilesForDownload: state.eventFilesForDownload.map((eventFile: any) =>
          eventFile.id === action.payload.eventFileId ? { ...eventFile, offsetInSeconds: action.payload.offsetInSeconds } : eventFile)
      }
    case TOGGLE_SHOW_SEGMENTED_FILES:
      return {
        ...state, showSegmentedFiles: !state.showSegmentedFiles
      }
    case TOGGLE_SHOW_INSTANCES_WITH_TEXT_FILES_ONLY:
      return {
        ...state,
        showInstancesWithTextFilesOnly: !state.showInstancesWithTextFilesOnly
      }
    default:
      return state;
  }
};
