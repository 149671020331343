export enum EventStatus {
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    RECORDING = "RECORDING",
    FINISHED = "FINISHED",
    PROCESSED = "PROCESSED",
    ERROR = "ERROR"
}
export enum RecordingInstanceStatus {
    RUNNING = "RUNNING",
    FINISHED = "FINISHED",
    PROCESSING = "PROCESSING",
    PROCESSED = "PROCESSED",
    FAILED = "FAILED",
    REJECTED = "REJECTED"
}
export enum ItemStatus {
    AVAILABLE = "AVAILABLE",
    REQUESTED = "REQUESTED",
    FINISHED = "FINISHED",
    FAILED = "FAILED"
}
export enum FileTypes {
    AUDIO_MP3 = "AUDIO_MP3",
    VIDEO_TS = "VIDEO_TS",
    HLS_M3U8 = "HLS_M3U8",
    VIDEO_WEBM = "VIDEO_WEBM",
    VIDEO_MP4 = "VIDEO_MP4",
    TXT = "TXT",
    ZIP = "ZIP",
    SPEAKERS = "SPEAKERS",
    FLOOR_AUDIO = "FLOOR_AUDIO",
    FLOOR_VIDEO = "FLOOR_VIDEO",
    LANGUAGE_AUDIO = "LANGUAGE_AUDIO",
    FULL_FLOOR_AUDIO = "FULL_FLOOR_AUDIO",
    FULL_LANGUAGE_AUDIO = "FULL_LANGUAGE_AUDIO",
    FULL_LANGUAGE_FLOOR_FILLED_AUDIO = "FULL_LANGUAGE_FLOOR_FILLED_AUDIO",
    LANGUAGE_FLOOR_FILLED_AUDIO = "LANGUAGE_FLOOR_FILLED_AUDIO",
    LANGUAGE_FLOOR_FILLED_VIDEO = "LANGUAGE_FLOOR_FILLED_VIDEO",
    CAPTIONS = "CAPTIONS",
    TRANSCRIPTION = "TRANSCRIPTION"
}
export const MediaFileType = new Map<FileTypes, string>([
    [FileTypes.VIDEO_MP4, 'video'],
    [FileTypes.FLOOR_VIDEO, 'video'],
    [FileTypes.LANGUAGE_FLOOR_FILLED_VIDEO, 'video'],
    [FileTypes.AUDIO_MP3, 'audio'],
    [FileTypes.FLOOR_AUDIO, 'audio'],
    [FileTypes.FULL_LANGUAGE_AUDIO, 'audio'],
    [FileTypes.FULL_LANGUAGE_FLOOR_FILLED_AUDIO, 'audio'],
    [FileTypes.LANGUAGE_AUDIO, 'audio'],
    [FileTypes.FULL_FLOOR_AUDIO, 'audio'],
    [FileTypes.LANGUAGE_FLOOR_FILLED_AUDIO, 'audio'],
    [FileTypes.CAPTIONS, 'caption'],
    [FileTypes.TRANSCRIPTION, 'transcription'],
    [FileTypes.SPEAKERS, 'other'],
    [FileTypes.TXT, 'other'],
    [FileTypes.ZIP, 'other'],
    [FileTypes.VIDEO_TS, 'other'],
    [FileTypes.VIDEO_WEBM, 'other'],
]);

export enum DownloadFileTypes {
    SRT = "SRT",
    VTT = "VTT",
    TEXT = "TEXT",
}

export interface IEvent {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    imageThumbnailUrl: string;
    status: EventStatus
}
export interface RecordingInstance {
    id: string;
    status: string | RecordingInstanceStatus;
    createdAt: string;
    transcriptionStatus: ItemStatus;
    languageFloorFilledVideoStatus: ItemStatus;
}

export interface IRecordingInstance extends RecordingInstance {
    isExpanded?: boolean;
    name: string;
    transcriptions?: ITranscriptionStatus[];
    mediaFilesCount?: number;
    floorTranscriptLanguage?: string;
    singleSegment: boolean;
    onlyCaptions: boolean;
}
export interface IRecordingInstanceItem {
    id: string;
    status: string;
    createdAt: string;
    transcriptionStatus: ItemStatus;
    languageFloorFilledVideoStatus: ItemStatus;
    isExpanded?: boolean;
    name: string;
    singleSegment: boolean;
    transcriptions: ITranscriptionStatus[];
    onlyCaptions: boolean;
}
export interface IEventFile {
    id: string;
    name: string;
    size: string;
    date: Date | string;
    fileType: FileTypes;
    languageCode: string;
    offsetInSeconds: number | null;
    isDownloading?: boolean;
    duration: string;
    mediaOffset?: number;
}

export interface IEventItem extends IEvent {
    isExpanded: boolean;
}

export interface ISort {
    id: number;
    value: string;
    direction: string;
}

export interface ITimezone {
    id: number;
    value: string;
}
export interface IMedia {
    src: string;
    status: number;
    type: "audio" | "video" | "error";
    apiOffset: number;
}

export interface IRemoteTrack {
    kind: 'subtitles' | 'captions' | 'descriptions' | 'chapters' | 'metadata';
    srclang: string;
    src: string;
    default: boolean;
    //videcemo
    offsetInSeconds: number;
}

export interface ITranscriptionStatus {
    sessionId: string;
    languageCode: string;
    floor: boolean;
    transcriptionStatus: ItemStatus;
    selected?: boolean;
}