import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import RecordingInstancesItem from "./RecordIngInstanceItem/RecordingInstanceItem";

import { useHistory } from "react-router-dom";
import { redirectToMainRoute } from "../../../shared/react-router.utils";
import {
  changeNoDataRedirect,
  changeShowEventList,
} from "../../../store/actions/eventAction";
import { RootState } from "../../../store/store";
import { IRecordingInstanceItem } from "../../../models/events";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import * as S from "../../../styled";
import Loader from "../../Loader/Loader";

const RecordingInstances = () => {
  const { t } = useTranslation();
  const recordingInstances = useSelector(
    (state: RootState) => state.event.recordingInstances
  );
  const isLoadingRecordingInstances = useSelector(
    (state: RootState) => state.event.isLoadingRecordingInstances
  );
  const redirectIfNoData = useSelector(
    (state: RootState) => state.event.redirectIfNoData
  );
  const checkShowInstancesWithTextFilesOnly = useSelector((state: RootState) => state.event.showInstancesWithTextFilesOnly);

  const history = useHistory();
  const dispatch = useDispatch();

  const filteredRecordingInstances = useMemo(() => checkShowInstancesWithTextFilesOnly
    ? recordingInstances
    : recordingInstances.filter(item => !item.onlyCaptions),
  [recordingInstances, checkShowInstancesWithTextFilesOnly]);

  useEffect(() => {
    if (redirectIfNoData) {
      redirectToMainRoute(history, "current", undefined, undefined);
      dispatch(changeNoDataRedirect());
      dispatch(changeShowEventList(true));
    }
    // eslint-disable-next-line
  }, [redirectIfNoData]);

  return (
    <>
      <Typography variant="h4" sx={{ opacity: 0.6,my:1 }}>
        {t("events.availableRecordings")}
      </Typography>
      <S.RecordingList>
        {isLoadingRecordingInstances && (
          <S.LoadingBlock
            width="100%"
            height="100%"
            padding="0"
          >
            <Loader />
          </S.LoadingBlock>
          )}
        {!isLoadingRecordingInstances && recordingInstances.length > 0 && filteredRecordingInstances.length > 0 &&
          filteredRecordingInstances.map(
            (recordingInstance: any) => {
              return (
                <RecordingInstancesItem
                  key={recordingInstance.id}
                  recordingInstance={recordingInstance}
                />
              );
            })
        }
        {!isLoadingRecordingInstances && recordingInstances.length > 0 && filteredRecordingInstances.length === 0 &&
            <S.CategoryEmptyMsg>
            {t("events.noInstancesWithTextFilesOnly").split("</br>").map((item, index) => (
              <React.Fragment key={index}>
                {item}<br/>
              </React.Fragment>
            ))}
            </S.CategoryEmptyMsg>
        }
        {recordingInstances.length === 0 && !isLoadingRecordingInstances && (
          <S.CategoryEmptyMsg>
            {t("events.noRecording")}
          </S.CategoryEmptyMsg>
        )}
      </S.RecordingList>
    </>
  );
};

export default RecordingInstances;
