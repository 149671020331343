import { Typography } from "@mui/material";
import styled, { keyframes } from "styled-components";

import palette from "../../material-ui/theme/colors";

interface ProgressFillProps {
  progress: number;
}

interface LoaderBarProps {
  progress: number;
}

const blinking = keyframes`
  0% {
    background-color: ${palette.primary.main};
  }
  50% {
    background-color: ${palette.primary[90]};
  }
  100% {
    background-color: ${palette.primary.main};
  }
`;

const ProgressBar = styled.div`
    width: 100%;
    height: 20px;
    border: 1px solid ${palette.grey[20]};
    border-radius: 10px;
    overflow: hidden;
    background-color: #f3f4f6;
    margin-bottom: 16px;
    z-index: 1;
    position: relative;
    text-align: center;
`;

const ProgressFill = styled.div<ProgressFillProps>`
    position: absolute;
    height: 100%;
    width: ${(props) => props.progress}%;
    animation: ${blinking} 1s infinite;
    z-index: -1;
`;

const LoaderBar = ({ progress }: LoaderBarProps) => {
  return (
    <ProgressBar>
        <ProgressFill progress={progress} />
        <Typography variant="p1" align="center" sx={{color: `${palette.common.text}`}}>
            {progress}%
        </Typography>
    </ProgressBar>
  );
};

export default LoaderBar;
